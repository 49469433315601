import { TABLA, CITA } from '@/utils/consts'
import { APIFilter } from '@/utils/api'
import { currentDateTime, combinarDateTime } from '@/utils/date'

export default {
  async insertLoperacioncrmCliente(Vue, idoperacioncrm, idcliente) {
    await Vue.$api.call(
      'loperacioncrm.insert',
      {
        values: {
          idoperacioncrm: idoperacioncrm,
          idcliente: idcliente,
        },
      },
    )
  },
  async insertLoperacioncrmClientePotencial(Vue, idoperacioncrm, idclientePotencial) {
    await Vue.$api.call(
      'loperacioncrm.insert',
      {
        values: {
          idoperacioncrm: idoperacioncrm,
          idcliente_potencial: idclientePotencial,
        },
      },
    )
  },
  async insertLoperacioncrmCliente(Vue, idoperacioncrm, idcliente) {
    await Vue.$api.call(
      'loperacioncrm.insert',
      {
        values: {
          idoperacioncrm: idoperacioncrm,
          idcliente: idcliente,
        },
      },
    )
  },
  async insertLoperacioncrmSistema(Vue, idoperacioncrm, idsistema) {
    await Vue.$api.call(
      'loperacioncrm.insert',
      {
        values: {
          idoperacioncrm: idoperacioncrm,
          idsistema: idsistema,
        },
      },
    )
    const apiFilter = new APIFilter()
    apiFilter.addExact('idsistema', idsistema)
    const resp = await Vue.$api.call('sistema.select', { filter: apiFilter })
    await this.insertLoperacioncrmCliente(Vue, idoperacioncrm, resp.data.result.dataset[0].idcliente)
  },
  async insertLoperacioncrmPresupuestocli(Vue, idoperacioncrm, idpresupuestocli) {
    await Vue.$api.call(
      'loperacioncrm.insert',
      {
        values: {
          idoperacioncrm: idoperacioncrm,
          idpresupuestocli: idpresupuestocli,
        },
      },
    )
    const apiFilter = new APIFilter()
    apiFilter.addExact('idpresupuestocli', idpresupuestocli)
    const resp = await Vue.$api.call('presupuestocli.select', { filter: apiFilter })
    if (resp.data.result.dataset[0].idcliente) {
      await this.insertLoperacioncrmCliente(Vue, idoperacioncrm, resp.data.result.dataset[0].idcliente)
    } else if (resp.data.result.dataset[0].idcliente_potencial) {
      await this.insertLoperacioncrmClientePotencial(Vue, idoperacioncrm, resp.data.result.dataset[0].idcliente_potencial)
    }
  },
  async insertarLineasOperacionCRM(Vue, tabla, idoperacioncrm, identificador) {
    if (tabla === TABLA.cliente) {
      await this.insertLoperacioncrmCliente(Vue, idoperacioncrm, identificador)
    } else if (tabla === TABLA.sistema) {
      await this.insertLoperacioncrmSistema(Vue, idoperacioncrm, identificador)
    } else if (tabla === TABLA.cliente_potencial) {
      await this.insertLoperacioncrmClientePotencial(Vue, idoperacioncrm, identificador)
    } else if (tabla === TABLA.presupuestocli) {
      await this.insertLoperacioncrmPresupuestocli(Vue, idoperacioncrm, identificador)
    }
  },
  async insert (Vue, formData, tabla, identificador, usuarioIdempleado) {
    const ahora = currentDateTime()
    const respOperacionCRM = await Vue.$api.call(
      'operacioncrm.insert',
      {
        values: {
          idtoperacioncrm: formData.idtoperacioncrm?.idtoperacioncrm || null,
          idserie_operacioncrm: formData.idserie_operacioncrm?.idserie_operacioncrm || null,
          idprioridad: formData.idprioridad,
          finicio: ahora,
          descripcion: formData.descripcion,
          observaciones: formData.observaciones,
          iddelegacion: formData.iddelegacion,
          iddepartamento: formData.iddepartamento,
          idinformador: formData.idinformador,
          idresponsable: formData.responsable?.idempleado,
          fproximarevision: formData.fproximarevision,
          fprevistafin: formData.fprevistafin,
          ffin: formData.ffin,
          fasignacion: ahora,
          idest_operacioncrm: formData.idest_operacioncrm,
        },
      },
    )
    const idoperacioncrm = respOperacionCRM.data.result.dataset[0].idoperacioncrm
    this.insertarLineasOperacionCRM(Vue, tabla, idoperacioncrm, identificador)
    if (formData.cita) {
      const fcita = combinarDateTime(formData.cita_fecha, formData.cita_inicio)
      let ffin
      if (formData.cita_fin) {
        ffin = combinarDateTime(formData.cita_fecha, formData.cita_fin)
      } else {
        ffin = null
      }
      await Vue.$api.call(
        'cita.insert',
        {
          values: {
            titulo: formData.cita_titulo,
            cita: formData.cita_cita,
            tipo: CITA.tipo.crm,
            estado: CITA.estado.pendiente,
            fcita: fcita,
            identificador: idoperacioncrm,
            ffin: ffin,
            idempleado: usuarioIdempleado,
          },
        },
      )
    }
    return respOperacionCRM.data.result.dataset[0]
  },
}
